import React from 'react'
import { Link } from 'react-router-dom';
import { env } from '../config';
import { HomePageIntroVector } from './SVGicons'

function HomePageIntro(props: any) {
  const { HomePageIntroContent } = props;

  return (
    <div className="py-10">
      <div className='flex flex-col lg:flex-row justify-between'>
        <div data-aos="fade-right" className='mx-auto'>
          <img className=' scale-[70%] md:scale-[90%] lg:scale[100%] ' src={`${env}${HomePageIntroContent?.IntroSVGImage?.data?.attributes?.url}`} alt="Unlock the Power of AI in People Management placeholder" />
        </div>
        <div className='lg:w-[60%] xl:w-[40%] 2xl:mr-[150px] md:px-0 px-2'>
          <div className='flex flex-col px-3 md:px-10  p-2  z-[2]'>
            <div className='py-3 my-1 text-[28px]  md:text-[36px] lg:text-[48px] font-extrabold ' data-aos="fade-up">{HomePageIntroContent?.IntroTitleText}</div>
            <div className='py-3 my-1 text-[16px] md:text-[20px] lg:w-full' data-aos="fade-up">{HomePageIntroContent?.IntroDescriptionText1}</div>
            <div className='py-3 my-1 text-[16px] md:text-[20px] lg:w-full' data-aos="fade-up">{HomePageIntroContent?.IntroDescriptionText2}</div>
            <Link to='https://hrmnest.com/app/' target="_blank">
              <button aria-label="Sign Up" className=' hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577]  w-fit  px-5 py-3 rounded-md text-white mr-4  text-[14px] md:text-[16px]' data-aos="zoom-in" >{HomePageIntroContent?.ButtonText}</button>
            </Link>
          </div>
        </div>

      </div>

    </div>
  )
}

export default HomePageIntro