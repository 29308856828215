import axios from 'axios';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { env } from '../config';
import * as Actions from "../redux/actions"

function ConnectCard(props: any) {
  const dispatch = useDispatch();

  const ConnectCardData: any = useSelector(
    (state: any) => state?.DataReducer?.ConnectCardContent
  );



  useEffect(() => {
    (async () => {
      await axios
        .get(`${env}/api/connect-card-content?populate=deep`)
        .then(async (response: any) => {
          dispatch(Actions.ConnectCardAction(response?.data?.data?.attributes));
          // console.log("ConnectCardDATA", response?.data?.data?.attributes);
        })
        .catch((error: any) => {
          console.log("error", error);
        });

    })()
  }, [dispatch])


  const redirectToSignUp = () => {

  }

  // < div className = 'flex  justify-center items-center lg:w-1/2 z-[2] bg-blue-500 ' >
  //   <div className=' flex  justify-end flex-row mx-auto w-full md:w-5/6  p-2 '>
  //     {/* <input type="text" className='flex-1 text-[16px] px-3 py-1 mr-2 outline-none rounded-md' data-aos="zoom-in" placeholder='Enter your email'/> */}
  //     <button className='hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577]  px-5 py-3 rounded-md text-white mr-4 ' data-aos="zoom-in">{ConnectCardData?.ButtonText}</button>
  //   </div>
  //     </div >



  const { background } = props;
  return (
    <div className={`pb-10 lg:py-20 px-5 lg:px-36  w-full bg-[${background}]`} data-aos="fade-down">
      <div className='lg:py-8 py-4 px-5 lg:px-10 bg-white rounded-[20px] flex flex-col lg:flex-row mx-auto BoxShadow relative overflow-hidden '>
        <div className='w-[250px] h-[250px] md:w-[450px] md:h-[450px] bg-[#D1E9E6] rounded-full absolute ml-[-120px] mt-[-150px] md:mt-[-200px] z-[1]' data-aos="zoom-in"></div>
        <div className='w-[250px] h-[250px] md:w-[350px] md:h-[350px] bg-[#D1E9E6] rounded-full absolute ml-[170px] sm:ml-[300px]   md:ml-[400px]  lg:ml-[600px] 2xl:ml-[1400px] mt-[130px] md:mt-[100px] z-[1] ' data-aos="zoom-in"></div>
        <div className='flex flex-col  lg:w-full p-2  z-[2]  '>
          <div className='px-3 py-1 rounded-md  w-fit my-1 bg-[#E67B25] font-bold' data-aos="fade-in">{ConnectCardData?.tagText}</div>
          <div className=' py-1 md:py-3 my-1 text-2xl md:text-3xl xl:text-[45px]   font-extrabold lg:w-full' data-aos="fade-in">{ConnectCardData?.ConnectCardTitle}</div>
          <div className=' py-1 md:py-3 my-1 text-[16px] md:text-[20px] lg:w-full flex' data-aos="fade-in">
            <div className='w-2/3'>
              {ConnectCardData?.ConnectCardDescription}
            </div>
            <div className='ml-auto hidden gap-2 lg:block'>
              <Link to='https://hrmnest.com/app/' target="_blank">
                <button className='hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577]  px-5 py-3 rounded-md text-white   sm:w-max mr-2  ' data-aos="zoom-in">{ConnectCardData?.ButtonText}</button>
              </Link>
            </div>

          </div>
          <Link to='https://.hrmnest.com/app/' target="_blank">
            <button className='hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577]  px-5 py-3 rounded-md text-white  sm:w-max  lg:hidden ' data-aos="zoom-in">{ConnectCardData?.ButtonText}</button>
          </Link>

        </div>

      </div>
    </div>
  )
}

export default ConnectCard