import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ConnectCard from '../components/ConnectCard'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Loader from '../components/Loader'
import ModulesPageTitleSection from '../components/ModulesPageTitleSection'
import SingleModuleSection from '../components/SingleModuleSection'
import { FeaturesPageTitleVector } from '../components/SVGicons'
import { env } from '../config'
import * as Actions from "../redux/actions"
import useSendRoute from '../components/useSendRoute'




function ModulesPage(props: any) {
  const dispatch = useDispatch();
  const ModuleListData: any = useSelector(
    (state: any) => state?.DataReducer?.ModuleListContent
  );

  useSendRoute()

  useEffect(() => {

    setTimeout(() => {
      (async () => {
        await axios
          .get(`${env}/api/modules?populate=deep`)
          .then(async (response: any) => {
            dispatch(Actions.ModuleListAction(response?.data?.data));
            // console.log("ModuleListDATA", response?.data?.data);
          })
          .catch((error: any) => {
            console.log("error", error);
          });
      })()
    }, 0);




  }, [dispatch])




  const { PageType } = props;
  const activeModuleData = ModuleListData.find((node: any) => (node?.attributes?.type === PageType))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [PageType])

  return (
    <>
      {
        activeModuleData?.attributes?.PageTitleContent?.ModuleTitleSVGImage?.data?.attributes?.url ?
          <>
            <Header activePage={"modules"} background={"#EAEAE0"} />
            <SingleModuleSection activeModuleData={activeModuleData?.attributes} />
            <ConnectCard background={"white"} />
            <Footer wave={true} background={"#FDF4ED"} />
          </> : <Loader background={"#EAEAE0"} />
      }
    </>
  )
}

export default ModulesPage