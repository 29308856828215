import React from 'react'
import { env } from '../config';
import { WorkFlowManagement } from './SVGicons';
import { Link } from 'react-router-dom';
import { LogGoogleAnalyticsEvent } from './logGoogleAnalyticsEvent';

function LandingPageGetStartedSection(props: any) {
    const { GetStartedSection, Intro } = props;
    return (
        <section className='relative text-[#1F3934] py-16 md:py-20 lg:my-[10%]'>
            <div className='text-[36px] md:text-[48px] font-bold flex flex-col lg:flex-row xl:justify-around xl:w-4/5 mx-5 xl:mx-auto'>
                <h1 data-aos="fade-up" className='px-3 md:px-0 text-left  lg:w-[60%] xl:w-[40%] flex flex-col justify-between'>
                    {GetStartedSection?.Text}
                    <div className=' lg:hidden my-5 flex justify-center'>
                        <img className='z-[7]  relative' src={`${env}${GetStartedSection?.Image?.data?.attributes?.url}`} alt="LoginScreen" />
                    </div>
                    <div className='text-[16px] lg:text-lg font-bold p-2 mt-5'>
                        {GetStartedSection?.ListTitle}
                    </div>
                    <div>
                        {GetStartedSection?.points?.map((node: any) => {
                            return (
                                <li className='text-lg list-none font-normal flex items-center gap-2 py-2'>
                                    <svg width="16" height="15" className='w-5 h-5 shrink-0' viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.93125 10.6875L11.975 5.64375L11.1875 4.85625L6.93125 9.1125L4.79375 6.975L4.00625 7.7625L6.93125 10.6875ZM8 14.625C7.0125 14.625 6.0845 14.4375 5.216 14.0625C4.347 13.6875 3.59375 13.1813 2.95625 12.5438C2.31875 11.9062 1.8125 11.153 1.4375 10.284C1.0625 9.4155 0.875 8.4875 0.875 7.5C0.875 6.5125 1.0625 5.58425 1.4375 4.71525C1.8125 3.84675 2.31875 3.09375 2.95625 2.45625C3.59375 1.81875 4.347 1.3125 5.216 0.9375C6.0845 0.5625 7.0125 0.375 8 0.375C8.9875 0.375 9.91575 0.5625 10.7847 0.9375C11.6532 1.3125 12.4062 1.81875 13.0438 2.45625C13.6813 3.09375 14.1875 3.84675 14.5625 4.71525C14.9375 5.58425 15.125 6.5125 15.125 7.5C15.125 8.4875 14.9375 9.4155 14.5625 10.284C14.1875 11.153 13.6813 11.9062 13.0438 12.5438C12.4062 13.1813 11.6532 13.6875 10.7847 14.0625C9.91575 14.4375 8.9875 14.625 8 14.625Z" fill="#024F46" />
                                    </svg>  {node?.point}
                                </li>

                            )
                        })}
                    </div>
                    <div className='flex  justify-start my-5'>
                        <Link to='https://hrmnest.com/app/' target="_blank">
                            <button onClick={(e) => LogGoogleAnalyticsEvent(e)} className='hover:bg-[#03776B]  w-max active:bg-[#024F46] bg-[#038577] px-5 py-3 rounded-md text-white mr-4 text-[14px] md:text-[16px]'>{GetStartedSection?.ButtonText}</button>
                        </Link>
                    </div>
                </h1>
                <div className='hidden lg:flex items-center '>
                    <img data-aos="fade-left" className='z-[7]  relative' src={`${env}${GetStartedSection?.Image?.data?.attributes?.url}`} alt="LoginScreen" />
                </div>
            </div>
            <svg data-aos="fade-left" className='absolute hidden lg:block right-0 bottom-[10%] scale-125 ' xmlns="http://www.w3.org/2000/svg" width="348" height="410" fill="none"><path fill="#D1E9E6" d="M0 190.5C0 85.29 85.29 0 190.5 0H348v410H0V190.5Z" /></svg>


        </section>
    )
}

export default LandingPageGetStartedSection

