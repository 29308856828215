import React from 'react'
import { Link } from 'react-router-dom';
import { env } from '../config';


function HomeFeaturesOverview(props: any) {
  const { HomePageFeatureOverviewContent } = props;
  return (
    <div className=" py-16 md:py-20 ">
      <div className="flex flex-col-reverse lg:flex-row justify-between ">
        <div className=" lg:w-[60%] xl:w-[60%] lg:ml-[100px] xl:ml-[200px] md:px-0 px-2">
          <div className="flex flex-col px-3 md:px-10 lg:px-2  p-2  ">
            <div className="px-3 py-1 rounded-md  w-fit my-1 bg-[#E67B25] font-bold" data-aos="zoom-in">
              {HomePageFeatureOverviewContent?.tagText}
            </div>
            <div className="py-3 my-1 text-[28px]  md:text-[36px] lg:text-[48px] font-extrabold  " data-aos="fade-up">
              {HomePageFeatureOverviewContent?.HomeFeatureOverviewTitle}
            </div>
            <div className="py-3 my-1  text-[16px] md:text-[20px]  lg:w-2/3" data-aos="fade-up">
              {HomePageFeatureOverviewContent?.HomeFeatureOverviewDescription}
            </div>

            <div className=" p-1  flex flex-col md:grid grid-cols-2 gap-2 w-full  ">
              {HomePageFeatureOverviewContent?.SingleFeatureInHomeCard?.map((node: any) => (
                <div key={node.id} className=" flex  m-1  flex-row  md:p-1 rounded-xl border-[#00000010] border items-center text-[16px] font-semibold w-full lg:max-w-[350px]" data-aos="zoom-in">
                  <div className='flex-shrink-0' >
                    <img className='scale-[70%]  md:scale-[90%]' src={`${env}${node?.SingleFeatureSVGImage?.data?.attributes?.url}`} alt={node?.SingleFeatureName + "svg"} />
                  </div>
                  <div className="ml-4 md:ml-1 text-[16px] lg:text-[18px] ">{node?.SingleFeatureName}</div>
                </div>
              ))
              }
            </div>
            <Link to="/features">
              <button className='bg-transparent px-5 py-3 mt-5 border-[#038577] border rounded-md hover:text-white hover:bg-[#038577] w-fit' data-aos="zoom-in">{HomePageFeatureOverviewContent?.HomeFeatureOverviewButtonText}</button>
            </Link>



          </div>
        </div>
        <div data-aos="fade-left" className=' flex justify-end'>
          {/* <HomePageFeaturesVector /> */}
          <img className=' hidden lg:flex xl:scale-[100%]' src={`${env}${HomePageFeatureOverviewContent?.FeatureSVGImage?.data?.attributes?.url}`} alt={"Feature svg"} />

        </div>

      </div>
    </div>

  )
}

export default HomeFeaturesOverview