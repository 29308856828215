import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Dummypage from "./Pages/Dummypage";
import FeaturesPage from "./Pages/FeaturesPage";
import "./index.css"
import ModulesPage from "./Pages/ModulesPage";
import { useDispatch, useSelector } from "react-redux";
import { env, googleTag } from "./config";
import axios from "axios";
import * as Actions from "./redux/actions"
import { useEffect } from "react";
import OfferingPage from "./Pages/OfferingPage";
import AboutPage from "./Pages/AboutPage";
import ContactPage from "./Pages/ContactPage";
import Aos from "aos";
import "aos/dist/aos.css"
import PrivacyPolicy from "./components/PrivacyPolicy";
import LandingPage from "./Pages/LandingPage";
import ReactGA from "react-ga4";



function App() {

  const dispatch = useDispatch();
  const HomeData: any = useSelector(
    (state: any) => state?.DataReducer?.HomePageContent
  );




  const getHomePageData = async () => {


    await axios
      .get(`${env}/api/home-page-content?populate=deep`)
      .then(async (response: any) => {
        dispatch(Actions.HomePageAction(response?.data?.data?.attributes));
        dispatch(
          Actions.FooterAction(response?.data?.data?.attributes?.FooterContent)
        );
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };



  ReactGA.initialize(googleTag);





  useEffect(() => {

    if (Object.keys(HomeData).length === 0) {

      setTimeout(() => {


        getHomePageData();
      }, 0);
    }


  }, [])

  // document.addEventListener("visibilitychange", () => {
  //   console.log("document.visibilityState", document.visibilityState);
  //   if (document.visibilityState === "visible") {
  //     document.title = "Lets scroll"
  //     console.log("documenttitle", document.visibilityState)
  //   } else if (document.visibilityState === "hidden") {
  //     console.log("documenttitle", document.visibilityState)
  //     document.title = "Come Back"


  //   }
  //   // Modify behavior…
  // });





  useEffect(() => {
    Aos.init({
      duration: 1000,
      useClassNames: true,
      animatedClassName: "animated"
    });

    Aos.refresh();
  })

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/modules/people-management" element={<ModulesPage PageType="people-management" />} />
          <Route path="/modules/leave-builder" element={<ModulesPage PageType="leave-builder" />} />
          <Route path="/modules/organization-operation" element={<ModulesPage PageType="organization-operation" />} />
          <Route path="/modules/hiring-management" element={<ModulesPage PageType="hiring-management" />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/offerings" element={<OfferingPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/lp/sign-up" element={<LandingPage />} />



          {/* <Route path="/about-us" element={<AboutPage/>}/> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;