import React from 'react'
import { SmallLinesinFeatureMainTitle } from './SVGicons'
import { env } from '../config';
import { Link } from 'react-router-dom';
import { LogGoogleAnalyticsEvent } from './logGoogleAnalyticsEvent';

function LandingPageWhyChooseSection(props: any) {
    const { WhyChooseSection } = props;
    return (
        <div id="whychoosehrmnest" className='py-16 md:py-20 '>
            <div className='flex flex-col lg:grid grid-cols-3 grid-rows-2 lg:w-fit mx-auto'>
                <div className='lg:rounded-tl-[50px] text-[#038577] lg:bg-[#038577] m-1 lg:w-[270px] lg:h-[270px] lg:shadow-lg text-[24px] font-bold lg:text-white px-6 lg:py-12 flex flex-col'>
                    <div className='text-center'>
                        {WhyChooseSection?.FeatureMainHeading}
                    </div>
                    <div className='lg:flex hidden'>
                        <SmallLinesinFeatureMainTitle />
                    </div>
                </div>
                {WhyChooseSection?.SingleSubFeatureContent.map((node: any, index: number) => {
                    return (
                        <div key={node?.SubFeatureTitle} className={`${index === 0 ? "" : index === 1 ? "lg:rounded-tr-[50px]" : index === 2 ? "lg:rounded-bl-[50px]" : index === 4 ? "lg:rounded-br-[50px]" : ""} lg:bg-white m-1 lg:w-[270px] lg:h-[270px] lg:shadow-lg p-2 flex flex-col justify-center text-center text-[#1F3934]`}>
                            <div className='flex justify-center'>
                                <div className=' '>

                                    <img src={`${env}${node?.SubFeatureSVGImage?.data?.attributes?.url}`} alt={node?.SubFeatureTitle + "SVG"} />
                                </div>
                            </div>
                            <div className='font-bold text-[16px] my-3'>{node?.SubFeatureTitle}</div>
                            <div className='text-[16px]'>{node?.SubFeatureDescription}</div>
                        </div>

                    )
                })}
            </div>
            <div className='flex justify-center'>
                <Link to='/features' >
                    <button onClick={(e) => LogGoogleAnalyticsEvent(e)} className='bg-transparent px-5 py-3 my-8 w-max  border-[#038577] border text-[14px] md:text-[16px] rounded-md hover:text-white hover:bg-[#03776B] active:bg-[#024F46]'>{WhyChooseSection?.ButtonText}</button>
                </Link>
            </div>

        </div>
    )
}

export default LandingPageWhyChooseSection