import ReactGA from "react-ga4";



export async function LogGoogleAnalyticsEvent(e: React.MouseEvent<HTMLButtonElement, MouseEvent>|React.MouseEvent<HTMLAnchorElement, MouseEvent>,optionalString=""){
    const eventString = (e?.target as HTMLElement).innerHTML
    let eventData:any={
        category: 'button_click',
        action: `${eventString}`,
         label:`${eventString}`
    }
   if(optionalString.length!==0){
    eventData={...eventData,action: `${eventString}-${optionalString}`, label:`${eventString}`}
   }

if(eventString.length!==0||eventString!==null||eventString!==undefined){
    ReactGA.event(eventData);

}

}