import React, { useEffect, useState } from "react";
import ModulesPageTitleSection from "./ModulesPageTitleSection";
// import { ModuleTabPointBullet, SmallLinesinFeatureMainTitle } from "./SVGicons";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { env } from "../config";

function SingleModuleSection(props: any) {





  const { activeModuleData, activeTabFromParent } = props;
  console.log(activeModuleData, "module_data");
  const [activeTab, setActiveTab] = useState<any>(
    activeModuleData?.ModuleTabs[0].tabName
  );

  const [visibleSlides, setVisibleSlides] = useState(4.5);

  useEffect(() => {

    var screeHeight = window.innerWidth;
    if (screeHeight > 1400) {

      setVisibleSlides(4.5);
    }
    else if (screeHeight <= 1400)
      setVisibleSlides(3.5);



  }, [window.innerWidth, document.body.clientWidth])



  useEffect(() => {
    setActiveTab(activeModuleData?.ModuleTabs[0].tabName);
    window.scrollTo(0, 0);
  }, [activeModuleData]);

  console.log(
    "popopopop",
    activeModuleData,
    activeTab,
    activeModuleData?.ModulePoints.filter(
      (node: any, i: any) => activeTab === node.tabName
    )
  );

  return (
    <>
      <ModulesPageTitleSection TitleData={activeModuleData.PageTitleContent} />
      <div className=" lg:p-10  flex my-10">
        <div className="mx-auto  p-1 w-full lg:w-full">
          <div className="">
            <div
              className="px-3 py-1 rounded-md mx-auto w-fit my-1 bg-[#E67B25] font-bold"
              data-aos="fade-down"
            >
              {activeModuleData?.tagText}
            </div>
          </div>

          {/*------------- Mobile view Slider-------------- */}
          <div className="flex lg:hidden  w-full h-fit">
            <CarouselProvider
              naturalSlideWidth={10}
              naturalSlideHeight={window.innerWidth < 415 ? 16 : window.innerWidth < 515 ? 14 : window.innerWidth < 615 ? 12 : window.innerWidth < 715 ? 10 : window.innerWidth < 815 ? 8 : window.innerWidth < 915 ? 7 : 7}
              totalSlides={activeModuleData?.ModuleTabs.length}
              orientation={"horizontal"}
              infinite={false}
              className="carousalContainer  pt-2  w-full mx-auto   flex justify-center"
              visibleSlides={1.1}
              isPlaying={false}
              dragEnabled={true}
              step={1}
              dragStep={1}
            >
              <div className="w-full  px-2">
                <Slider>
                  {activeModuleData?.ModuleTabs?.map((node: any, i: any) => (
                    <Slide
                      key={node.id}
                      index={i}

                    >
                      <div className="flex flex-col bg-[#f7f7f7]  border-[#acaaaa] mt-2 mx-1 p-2 rounded-lg ">
                        <div
                          // data-aos="zoom-in"
                          key={node.id}
                          className={`  justify-center flex flex-col font-bold text-lg  items-center  mx-3 cursor-pointer border-b  pb-2`}
                        >
                          <div className="mx-auto my-2 ">
                            <img
                              className=" "
                              src={`${env}${node?.tabSVG?.data?.attributes?.url}`}
                              alt={"feature title svg"}
                            />
                          </div>
                          <div className="text-[16px]">{node.tabName}</div>
                        </div>
                        <div className="flex-col flex min-h-[400px] max-h-[400px] overflow-y-scroll bg-blue- ">
                          {activeModuleData?.ModulePoints?.filter(
                            (Childnode: any, i: any) =>
                              Childnode.tabName === node.tabName
                          )?.map((Childnode: any, i: any) => (
                            <div

                              key={Childnode.id}
                              className="my-4 item-center p-1 flex flex-row lg:max-w-[500px] text-[14px] bg-pink- "
                            //  data-aos="fade-down"
                            >
                              <div
                                style={{ boxShadow: "0px 0px 32.5px rgba(0, 0, 0, 0.1)" }}
                                className=" min-w-[30px] min-h-[30px] lg:min-w-[50px] hidden  lg:min-h-[50px] lg:max-w-[50px] lg:max-h-[50px] max-w-[30px] max-h-[30px] bg-[white] border-[3px] border-[#038577] border-dashed   rounded-full md:flex justify-center my-auto items-center mx-2 "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32"
                                  height="15"
                                  viewBox="0 0 32 15"
                                  fill="none"
                                >
                                  <path
                                    d="M0 0C4.875 3.25 18.0375 7.8 31.6875 0C26.2708 6.22917 12.35 14.95 0 0Z"
                                    fill="#038577"
                                  />
                                  <path
                                    d="M4.98216 5.96813C7.52536 8.46134 15.3568 11.7045 24.5223 8.91644C20.5061 11.8344 10.6124 16.1668 4.98216 5.96813Z"
                                    fill="#038577"
                                  />
                                </svg>
                              </div>


                              <div className=" h-fit my-auto text-[16px] md:text-[20px] text-center md:text-left">
                                {Childnode.tabPointContent}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Slide>
                  ))}
                </Slider>
              </div>
            </CarouselProvider>
          </div>
          {/* Mobile View Slider End */}

          {/*-------------- Desk View Slider-------------- */}
          <div className=" p-3 items-center my-1 w-full mx-auto justify-center hidden lg:flex flex-row">
            {activeModuleData?.ModuleTabs.length > 4 ? (
              <CarouselProvider
                naturalSlideWidth={10}
                naturalSlideHeight={5}
                totalSlides={activeModuleData?.ModuleTabs.length}
                orientation={"horizontal"}
                infinite={false}
                className="carousalContainer  w-full mx-auto flex justify-center"
                visibleSlides={visibleSlides}
                isPlaying={false}
                dragEnabled={true}
                step={1}
                dragStep={1}
              >
                <div className="w-full p-2">
                  <Slider className=" ">
                    {activeModuleData?.ModuleTabs?.map((node: any, i: any) => (
                      <Slide key={node.id} index={i}>
                        <div
                          // data-aos="zoom-in"
                          key={node.id}
                          className={`justify-center text-center flex flex-col font-bold text-sm md:text-md lg:text-lg rounded-[10px] items-center  mx-2 cursor-pointer border border-[#e7e7e7] min-h-[120px] xl:min-w-[250px]`}
                          onClick={() => setActiveTab(node?.tabName)}
                          style={{
                            boxShadow: `${activeTab === node?.tabName
                              ? "0px 0px 20px rgba(0, 0, 0, 0.1"
                              : "none"
                              }`,
                            backgroundColor: `${activeTab === node?.tabName ? "white" : "none"
                              }`,
                            color: `${activeTab === node?.tabName
                              ? "#038577"
                              : "#1F3934"
                              }`,
                          }}
                        >
                          <div className="mx-auto my-2 lg:flex hidden">
                            <img
                              className=""
                              src={`${env}${node?.tabSVG?.data?.attributes?.url}`}
                              alt={"feature title svg"}
                            />
                          </div>
                          <div className="text-sm md:text-md lg:text-lg">{node.tabName}</div>
                        </div>
                      </Slide>
                    ))}
                  </Slider>
                </div>
              </CarouselProvider>
            ) : (
              <>
                {activeModuleData?.ModuleTabs?.map((node: any, i: any) => (
                  <div
                    // data-aos="zoom-in"
                    key={node.id}
                    className={`justify-center text-center flex flex-col font-bold text-lg rounded-[10px] items-center  mx-2 cursor-pointer border border-[#e7e7e7] xl:min-w-[300px] xl:max-w-[300px] xl:min-h-[120px]  `}
                    onClick={() => setActiveTab(node?.tabName)}
                    style={{
                      boxShadow: `${activeTab === node?.tabName
                        ? "0px 0px 20px rgba(0, 0, 0, 0.1"
                        : "none"
                        }`,
                      backgroundColor: `${activeTab === node?.tabName ? "white" : "none"
                        }`,
                      color: `${activeTab === node?.tabName ? "#038577" : "#1F3934"
                        }`,
                    }}
                  >
                    <div className="mx-auto my-2">
                      <img
                        className=""
                        src={`${env}${node?.tabSVG?.data?.attributes?.url}`}
                        alt={"feature title svg"}
                      />

                    </div>
                    <div>{node.tabName}</div>
                  </div>
                ))}
              </>
            )}
          </div>
          {/* Desk View Slider End*/}

          {/*-------------- Desk View Points --------------*/}


          <div className=" hidden lg:flex flex-col xl:flex-row  justify-between  xl:px-10 py-10  mx-auto  ">





            {/* Points start */}
            <div className="flex flex-col  mx-2 p-2 w-full h-fit ">
              {activeModuleData?.ModulePoints?.filter(
                (node: any, i: any) => activeTab === node.tabName
              )?.map((node: any, i: any) => (
                <div
                  key={node.id}
                  // xl:max-w-[500px]
                  className="my-4 item-center p-1 flex flex-row  w-3/4   text-[16px] "
                //  data-aos="fade-down"
                >
                  <div
                    style={{ boxShadow: "0px 0px 32.5px rgba(0, 0, 0, 0.1)" }}
                    className=" min-w-[30px] min-h-[30px] lg:min-w-[50px] lg:min-h-[50px] lg:max-w-[50px] lg:max-h-[50px] max-w-[30px] max-h-[30px] bg-[white] border-[3px] border-[#038577] border-dashed   rounded-full flex justify-center my-auto items-center mx-2 "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="15"
                      viewBox="0 0 32 15"
                      fill="none"
                    >
                      <path
                        d="M0 0C4.875 3.25 18.0375 7.8 31.6875 0C26.2708 6.22917 12.35 14.95 0 0Z"
                        fill="#038577"
                      />
                      <path
                        d="M4.98216 5.96813C7.52536 8.46134 15.3568 11.7045 24.5223 8.91644C20.5061 11.8344 10.6124 16.1668 4.98216 5.96813Z"
                        fill="#038577"
                      />
                    </svg>
                  </div>
                  <div className=" h-fit my-auto w-full  text-[16px] md:text-[20px]">
                    {node.tabPointContent}
                  </div>
                </div>
              ))}
            </div>
            {/* Points end */}


            {/* Screenshot Image */}
            <div
              data-aos="zoom-in"
              className=" mt-2 xl:mt-0   xl:w-full justify-center rounded-[10px] xl:rounded-[30px]  flex mx-auto items-center "
            >
              {activeModuleData?.ModuleTabs?.filter(
                (node: any) => node.tabName === activeTab
              ).map((node: any) => (
                <>
                  {node?.tabDisplayScreenShot?.data?.attributes?.url ? (
                    <img
                      key={node.id}
                      className="scale-[90%] lg:scale-[100%] xl:scale-[110%] rounded-[10px] "
                      src={`${env}${node?.tabDisplayScreenShot?.data?.attributes?.url}`}
                      alt={"feature title svg"}
                    />
                  ) : (
                    <div className="flex justify-center items-center   px-10 flex-col h-full w-full rounded-[10px] xl:rounded-[30px] border border-[lightgray]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 96 960 960"
                        width="48"
                      >
                        <path
                          fill="lightgray"
                          d="M600 736h160V576h-40v120H600v40ZM200 496h40V376h120v-40H200v160Zm130 440v-80H140q-24 0-42-18t-18-42V276q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H630v80H330ZM140 796h680V276H140v520Zm0 0V276v520Z"
                        />
                      </svg>

                      <div className="text-[lightgray]">
                        {" "}
                        No Image available
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
            {/* Screenshot Image End */}




          </div>

          {/* Desk View Slider End */}
        </div>
      </div>
    </>
  );
}

export default SingleModuleSection;
