import React from 'react'
import { Link } from 'react-router-dom';
import { env } from '../config';
import { Birds, DownWave } from './SVGicons'

function HomePageTitle(props: any) {
  const { HomePageFaceContent } = props;




  // console.log("HOMEPAGETITLECONTENT",HomePageFaceContent);

  return (
    <>
      <section className='relative z-[1]' data-aos="fade-down">
        <div className=' pt-12 lg:pt-60 text-[#1F3934] lg:pl-24 bg-[#FAE7D7] flex flex-col-reverse lg:flex-row justify-between'>
          <div className=' lg:w-fit px-5 lg:px-10 py-5 flex flex-col'>
            <h1 className='text-[36px] md:text-[48px]  py-4 lg:w-[90%] font-extrabold' data-aos="fade-in">{HomePageFaceContent?.HomePageFaceTitle}</h1>
            <div className='py-4 text-[16px] md:text-[20px] lg:w-2/4' data-aos="fade-in">{HomePageFaceContent?.HomePageFaceDescription}</div>
            <div className='py-4 flex flex-row'>
              <Link to='https://hrmnest.com/app/' target="_blank">


                <button className='hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577]  px-5 py-3 rounded-md text-white mr-4  text-[14px] md:text-[16px] ' data-aos="zoom-in" >{HomePageFaceContent?.FilledButtonText}</button>
              </Link>
              <button className='bg-transparent px-5 py-3 border-[#038577] border text-[14px] md:text-[16px] rounded-md hover:text-white hover:bg-[#03776B] active:bg-[#024F46]' data-aos="zoom-in" onClick={() => window.scrollTo(0, 800)}>{HomePageFaceContent?.OutlineButtonText}</button>
            </div>
          </div>
          <div className=''>
            <div data-aos="slide-down" className=''>
              <img className=' hidden lg:flex scale-[20%] mx-auto  sm:scale-[40%]  md:scale-[60%] lg:scale-[80%] xl:scale-[100%] z-[1]' src={`${env}${HomePageFaceContent?.HomeFaceSVGImage?.data?.attributes?.url}`} alt="Birds and Nest" />
            </div>
          </div>
        </div>

      </section>
      <div data-aos="fade-down" className='z-[1]'>
        <DownWave background="#FAE7D7" />
      </div>
    </>
  )
}

export default HomePageTitle