import React from 'react'
import { env } from '../config';

function OfferingPageDeveloperConnect(props: any) {
  const { OfferingPageDeveloperConnectContent } = props;
  return (
    <div className="px-5 md:px-10 lg:my-20">
      <div className=" mx-auto lg:w-5/6 flex flex-col-reverse lg:flex-row justify-between">


        <div className=" flex flex-col p-2 my-5 lg:my-0 lg:w-1/2 ">
          <div className="font-extrabold text-[36px] lg:text-[48px] my-1 p-1" data-aos="fade-up">{OfferingPageDeveloperConnectContent?.OfferingPageDeveloperConnectTitle}</div>
          <div className="my-4 p-1 text-[16px] md:text-[20px] " data-aos="fade-up">{OfferingPageDeveloperConnectContent?.OfferingPageDeveloperConnectDescription1}</div>
          <div className="my-1 p-1 text-[16px] md:text-[20px] " data-aos="fade-up">{OfferingPageDeveloperConnectContent?.OfferingPageDeveloperConnectDescription2}</div>
          <div className="flex flex-col p-1">
            {
              OfferingPageDeveloperConnectContent?.OfferingPageDeveloperConnectFeatures?.map((node: any) => (
                <div key={node.id} className="flex flex-row my-5 items-center" data-aos="zoom-in">
                  <div
                    style={{ boxShadow: "0px 0px 32.5px rgba(0, 0, 0, 0.1)" }}
                    className=' min-w-[30px] min-h-[30px] lg:min-w-[50px] lg:min-h-[50px] lg:max-w-[50px] lg:max-h-[50px] max-w-[30px] max-h-[30px] bg-[white] border-[3px] border-[#038577] border-dashed   rounded-full flex justify-center items-center mx-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="15" viewBox="0 0 32 15" fill="none"  >
                      <path d="M0 0C4.875 3.25 18.0375 7.8 31.6875 0C26.2708 6.22917 12.35 14.95 0 0Z" fill="#038577" />
                      <path d="M4.98216 5.96813C7.52536 8.46134 15.3568 11.7045 24.5223 8.91644C20.5061 11.8344 10.6124 16.1668 4.98216 5.96813Z" fill="#038577" />
                    </svg>
                  </div>
                  <div className=' h-fit my-auto text-[16px] md:text-[20px] '>{node?.OfferingPageDeveloperConnectFeatureText}</div>
                </div>
              ))

            }


          </div>
          {/* <div>
          <a href='https://dev.hrmnest.com' target={"_blank"}>

        <button className=' px-5 py-3 rounded-md text-white mr-4 hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577]  mt-5' data-aos="zoom-in">{OfferingPageDeveloperConnectContent?.OfferingPageDeveloperConnectButtonText}</button>
          </a>
        </div> */}


        </div>


        <div className="flex justify-center item-center mx-1 " data-aos="zoom-in">
          <img alt={OfferingPageDeveloperConnectContent?.OfferingPageDeveloperConnectDescription2} className=' scale-[80%] md:scale-[85%]  lg:scale-[95%] lg:scale-[150%]' src={`${env}${OfferingPageDeveloperConnectContent?.OfferingPageDeveloperConnectSVGImage?.data?.attributes?.url}`} />

        </div>



      </div>

    </div>

  )
}

export default OfferingPageDeveloperConnect