import React, { useState } from "react";

import { useRef } from "react";
import axios from "axios";
import { env } from "../config";

function ContactForm(props: any) {
  const { ContactData } = props;

  // console.log("CONTACT DTA", ContactData);
  const emptyState = {
    name: "",
    companyName: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState(emptyState);
  const [flag, setFlag] = useState("initial");

  const handleForm = (e: any) => {
    const newFormData: any = { ...formData };
    newFormData[e.target.id] = e.target.value;
    setFormData(newFormData);
  };

  const sendMail = () => {

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(!formData.email.match(mailformat))
    {
      alert("Provide a valid email");
      return;
    }

    // e.preventDefault();
    console.log(formData);
    if (
      formData.companyName === "" ||
      formData.name === "" ||
      formData.phone === "" ||
      formData.message === "" ||
      formData.email === ""
    ) {
      setFlag("notcomplete");
    } else {
      setFlag("loading");
      axios
        .post(`${env}/api/contact-emails`, {
          data: formData,
        })
        .then(() => {
          setFormData(emptyState);
          setFlag("success");
          setTimeout(() => {
            setFlag("initial");
          }, 10000);
        })
        .catch(() => {
          setFlag("error");
        });
    }
  };

  return (
    <div className="w-full pt-1 md:pt-28 pb-4 bg-[white] " >
     

      {/* {flag === "inital" && (
        <div className="flex flex-col bg-red-50 text-red-500 w-3/6 mx-auto p-3 border-red-500 border-2 rounded-md mt-20">
          Error
        </div>
      )} */}
      <div className="flex flex-col bg-white w-[95%] xl:w-5/6 2xl:w-[45%] mx-auto py-3 rounded-md my-2 md:my-10">
        {/* <form
          action="#"
          // onSubmit={(e) => sendMail(e)}
          // method="post"
          encType="text/plain"
        > */}

        <div className="flex flex-col xl:flex-row  px-3 justify-between">
          <div className="flex flex-col p-3  xl:w-1/2 mx-1 " data-aos="zoom-in">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">Your Name *</label>
            <input
              type="text"
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-[14px] placeholder:text-[14px] placeholder:text-[#D7D7D7]"
              placeholder="First Name  Last Name"
              id="name"
              value={formData.name}
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
              required
            />
          </div>
          <div className="flex flex-col  p-3 xl:w-1/2 mx-1" data-aos="zoom-in">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">Company Name *</label>
            <input
              required
              value={formData.companyName}
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-14 placeholder:text-[14px] placeholder:text-[#D7D7D7]"
              placeholder="Company Name"
              type="text"
              id="companyName"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
            />
          </div>
        </div>
        <div className="flex flex-col xl:flex-row  px-3 justify-between">
        <div className="flex flex-col p-3 xl:w-1/2 mx-1" data-aos="zoom-in">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">Phone *</label>
            <input
              required
              value={formData.phone}
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-14 placeholder:text-[14px] placeholder:text-[#D7D7D7]"
              placeholder="-- --- ---- ---"
              type="number"
              id="phone"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
            />
          </div>
          <div className="flex flex-col p-3 xl:w-1/2 mx-1" data-aos="zoom-in">
            <label className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">Email *</label>
            <input
              required
              value={formData.email}
              className="border-[1px] border-[#B0B0B0] rounded-md h-10 px-3 py-2 text-14 placeholder:text-[14px]  placeholder:text-[#D7D7D7]"
              placeholder="eg : name@domain.com"
              type="email"
              id="email"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
            />
          </div>
       
        </div>
        <div className="px-4">
          <div className="flex flex-col p-3 " data-aos="zoom-in">
            <label htmlFor="" className="text-[#868686] md:text-[12px] text-[14px] lg:text-[16px]">
              Message *
            </label>
            <textarea
              required
              value={formData.message}
              name=""
              id="message"
              onChange={(e) => {
                handleForm(e);
                setFlag("initial");
              }}
              // cols={30}
              rows={4}
              placeholder="Jot down your query here..."
              className="border-[1px] resize-none border-[#B0B0B0] rounded-md  px-3 py-2 text-14 placeholder:text-[14px] placeholder:text-[#D7D7D7]"
            ></textarea>
          </div>
        </div>
        <div className="px-7 py-2 flex justify-center xl:justify-end ">
          <button
            // type="submit"
            // type="reset"
            data-aos="zoom-in"
            
            type="button"
            disabled={
              flag === "loading"
                ? true
                : flag === "initial"
                ? false
                : flag === "notcomplete"
                ? true
                : false
            }
            onClick={() => sendMail()}
            style={{
              cursor:
                flag === "notcomplete" || flag === "loading"
                  ? "not-allowed"
                  : "pointer",
            }}
            className="font-body font-medium  w-full sm:w-auto text-sm md:text-md lg:py-3 lg:px-5 border  px-6 py-4 rounded-md text-white hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577] hover:border-transparent "
          >
            {flag === "loading"
              ? "Sending.."
              : flag === "initial"
              ? "Submit"
              : flag === "success"
              ? "Submit"
              : "Submit"}
          </button>
          {/* </form> */}
        </div>
        <div className="px-6">
          {flag === "success" && (
            <div
              
              className="flex flex-col bg-white text-[#1D9185] w-full mx-auto p-2 md:p-3  border-[#1D9185] border-2 rounded-md mt-2 font-semibold md:text-sm text-xs"
            >
              Success
              <div className=" py-1 md:py-2 text-[#1D9185] font-normal">
                Your response was submitted
              </div>
            </div>
          )}
          {flag === "error" && (
            <div
              
              className="flex flex-col bg-white text-[#911D29] w-full mx-auto  p-2 md:p-3  border-[#911D29] border-2 rounded-md mt-2 font-semibold md:text-sm text-xs"
            >
              Error
              <div className=" py-1 md:py-2 text-[#911D29] font-normal">
                There seems to be some error . Please try again
              </div>
            </div>
          )}
          {flag === "notcomplete" && (
            <div
              
              className="flex flex-col bg-white text-[#2590E6] w-full mx-auto p-2 md:p-3 border-[#2590E6] border-2 rounded-md mt-2  font-semibold md:text-sm text-xs"
            >
              Incomplete form
              <div className=" py-1 md:py-2 text-[#2590E6] font-normal">
                Please fill all fields to submit
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
