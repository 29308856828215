import React from 'react'
import Star from './star';

function TestimonialCard(props: any) {

    const { node, index } = props;
    const stars = [1, 2, 3, 4, 5]

    return (
        <div data-aos={`${index === 0 ? "flip-left" : index === 1 ? "flip-up" : "flip-right"}`} className={` z-[3] relative h-full lg:w-1/3 border-[2px] rounded-[50px] overflow-hidden BoxShadow lg:p-5 xl:p-10 my-5 lg:my-0 `}>
            <svg className='absolute  z-[1] bottom-0 right-0' width="88" height="94" viewBox="0 0 88 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 132C0 59.0984 59.0984 0 132 0V155H0V132Z" fill="#D1E9E6" />
            </svg>
            <div className=' text-[#1F3934]  my-5 lg:my-0 overflow-hidden  flex flex-col items-center text-ellipsis   '>
                <div className='font-bold text-center'>
                    {node?.Title}
                </div>
                <div className={`font-normal my-7 mx-auto w-[90%] lg:h-[190px] italic  blocktext `}   >
                    {node?.Description}

                </div>
                <div className='mb-3'>
                    {node?.By}
                </div>
                <div className='flex gap-2'>
                    {stars?.map((star: any, index: number) => {
                        return (
                            <Star key={Math.random} maxNumber={node?.NumerOfStars} index={index + 1} />
                        )
                    })}
                </div>

            </div>

        </div>
    )
}

export default TestimonialCard