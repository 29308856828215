import React from 'react'
import { env } from '../config';

function LandingPageSolution(props: any) {
    const { SolutionSection } = props;
    return (
        <div className='text-[#1F3934] py-16 md:py-20 px-5 md:px-10 lg:my-[2.5%] flex flex-col justify-start items-center mx-auto  xl:w-3/4'>
            <div className='text-[36px] md:text-[48px] font-extrabold text-center  '>
                {SolutionSection?.Title}
            </div>
            <div className='text-base md:text-lg font-normal py-6 xl:w-[60%]  text-center'>
                {SolutionSection?.Description}
            </div>
            <div className='grid md:grid-cols-2 gap-7  xl:gap-5 my-7'>
                {SolutionSection?.SolutionCard?.map((node: any, index: number) => {
                    return <div key={node?.id} className={` flex  m-1  flex-row  gap-3 md:p-2   items-center text-[16px] font-semibold `} data-aos-delay={`${index === 0 ? index * 0 : index * 150}`} data-aos="fade-up">
                        <img className='scale-[70%]  md:scale-[90%] shrink-0 mb-auto' src={`${env}${node?.image?.data?.attributes?.url}`} alt={node?.Title} />
                        <div className='flex flex-col'>
                            <div className=" text-[16px] lg:text-[18px] font-bold ">{node?.Title}</div>
                            <div className="text-[16px]  font-normal">{node?.Description}</div>
                        </div>


                    </div>
                })}


            </div>
        </div>
    )
}

export default LandingPageSolution