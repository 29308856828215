import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function FooterLinkSection(props: any) {
  const { title, data } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  console.log("data", data)

  return (
    <div
      className="flex flex-col sm:p-2 w-auto  "
    // data-aos="fade-up"
    >
      <div
        className="mb-2 md:mb-4 font-bold text-md lg:text-lg xl:text-2xl  flex flex-row cursor-pointer sm:cursor-auto justify-between"
        style={{
          WebkitTapHighlightColor: "rgba(0,0,0,0)",

        }}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <h1>{title}</h1>
        {!menuOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="my-[2px] sm:hidden flex "
            style={{ transform: "scale(0.7)" }}
            height="24"
            width="24"
          >
            <path
              fill="gray"
              d="M12 19q-.425 0-.712-.288Q11 18.425 11 18v-5H6q-.425 0-.713-.288Q5 12.425 5 12t.287-.713Q5.575 11 6 11h5V6q0-.425.288-.713Q11.575 5 12 5t.713.287Q13 5.575 13 6v5h5q.425 0 .712.287.288.288.288.713t-.288.712Q18.425 13 18 13h-5v5q0 .425-.287.712Q12.425 19 12 19Z"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"
            className="my-[2px] sm:hidden flex "
            style={{ transform: "scale(0.7)" }}>
            <path d="M6 13q-.425 0-.713-.288Q5 12.425 5 12t.287-.713Q5.575 11 6 11h12q.425 0 .712.287.288.288.288.713t-.288.712Q18.425 13 18 13Z" fill="gray" />
          </svg>
        )}
      </div>

      {data.map((node: any) => (
        <div key={node.text}>
          <div className=" hidden sm:flex">
            <div

              className="mb-3 text-[14px] xl:text-[18px]  h-[20px] "
            >
              {node?.type === "inside-link" ?


                <Link
                  to={`${node.path}`}
                  className="hover:decoration-[#028577]  max-h-full"
                >
                  <div className=" hover:border-b-[2px] w-fit border-[#028577] xl:hover:text-[18.1px] hover:text-[14.1px] ">
                    {node.text}
                  </div>
                </Link>

                :
                <a href={node?.path} target="_blank">
                  <div className=" hover:border-b-[2px] w-fit border-[#028577]  xl:hover:text-[18.1px] hover:text-[14.1px] ">
                    {node.text}
                  </div>
                </a>
              }








            </div>
          </div>

          <div className="sm:hidden">
            {menuOpen ? (
              <div
                key={node.text}
                className="mb-3 text-[14px] xl:text-[18px]  h-[20px] "
              >

                {
                  node?.type === "inside-link" ?

                    <Link
                      to={`${node.path}`}
                      className="hover:decoration-[#028577] max-h-full"
                    >
                      <div className="  w-fit border-[#028577]   ">
                        {node.text}
                      </div>
                    </Link> :
                    <a href={node?.path} target="_blank">
                      <div className="  w-fit border-[#028577]    ">
                        {node.text}
                      </div>
                    </a>

                }




              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}

export default FooterLinkSection;
