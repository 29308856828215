import React from 'react'
import { Link } from 'react-router-dom';
import { LogGoogleAnalyticsEvent } from './logGoogleAnalyticsEvent';

function SinglePricingCard(props: any) {

  const navigate = () => {
    console.log("asdojna")
    const url = "https://hrmnest.com/app/";
    window.open(url, '_blank');
  }

  const { SinglePricingCardData } = props;

  return (
    <div className="mx-2 md:mx-0 my-2 lg:my-0 bg-white flex flex-col justify-between p-[20px] lg:min-h-[500px] min-w-[30%] rounded-tl-[50px] rounded-tr-[10px] rounded-bl-[10px] rounded-br-[50px]    relative overflow-hidden BoxShadow border-2 border-[lightgray] hover:border-[#E67B25] PricingCard" data-aos="zoom-in">
      <div className='w-[330px] h-[330px] bg-[#D1E9E6] rounded-full absolute  ml-[150px] md:ml-[600px] lg:ml-[180px] mt-[130px]  lg:mt-[280px] z-[1]' data-aos="zoom-in"></div>

      <div className="p-1 z-[2]">
        <div className="flex flex-row item-center bg-white justify-between p-2 my-2">
          <div className="font-bold text-[24px] text-left " data-aos="zoom-down">{SinglePricingCardData?.PricingType}</div>
          <div className=' flex items-center'>
            <svg width="35" height="16" viewBox="0 0 35 16" className='PricingCardSVGDesign' fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0C5.35714 3.57143 19.8214 8.57143 34.8214 0C28.869 6.84524 13.5714 16.4286 0 0Z" />
              <path d="M5.47614 6.55671C8.27086 9.2965 16.8769 12.8604 26.9488 9.79662C22.5354 13.0032 11.6632 17.7641 5.47614 6.55671Z" />
            </svg>
          </div>
        </div>
        {/* <div className="text-[#A9A9A9] text-left text-[16px]  my-1 p-2" data-aos="zoom-down">
        {SinglePricingCardData.tagline}
      </div> */}
        <div className="font-bold text-[28px] text-left  p-2  lg:h-[58px] text-[#1f3934] flex" data-aos="zoom-down">{SinglePricingCardData?.amount} <span className='ml-1 text-sm flex font-normal'>{SinglePricingCardData?.unit}</span></div>
        <div className="flex flex-col ">
          {
            SinglePricingCardData?.SinglePricingCardFeatures?.map((node: any) => (
              <div className="my-1 flex flex-row  items-center " data-aos="zoom-down" key={node.id}>
                <svg width="16" className="" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.93125 10.6875L11.975 5.64375L11.1875 4.85625L6.93125 9.1125L4.79375 6.975L4.00625 7.7625L6.93125 10.6875ZM8 14.625C7.0125 14.625 6.0845 14.4375 5.216 14.0625C4.347 13.6875 3.59375 13.1813 2.95625 12.5438C2.31875 11.9062 1.8125 11.153 1.4375 10.284C1.0625 9.4155 0.875 8.4875 0.875 7.5C0.875 6.5125 1.0625 5.58425 1.4375 4.71525C1.8125 3.84675 2.31875 3.09375 2.95625 2.45625C3.59375 1.81875 4.347 1.3125 5.216 0.9375C6.0845 0.5625 7.0125 0.375 8 0.375C8.9875 0.375 9.91575 0.5625 10.7847 0.9375C11.6532 1.3125 12.4062 1.81875 13.0438 2.45625C13.6813 3.09375 14.1875 3.84675 14.5625 4.71525C14.9375 5.58425 15.125 6.5125 15.125 7.5C15.125 8.4875 14.9375 9.4155 14.5625 10.284C14.1875 11.153 13.6813 11.9062 13.0438 12.5438C12.4062 13.1813 11.6532 13.6875 10.7847 14.0625C9.91575 14.4375 8.9875 14.625 8 14.625Z" fill="#024F46" />
                </svg>
                <div className="mx-2 ">{node?.featurePoint}</div>
              </div>

            ))
          }

        </div>


      </div>
      {/* <a href='https://hrmnest.com/app/' > */}
      <button onClick={(e) => { window.open("https://hrmnest.com/app/", "_blank"); LogGoogleAnalyticsEvent(e, SinglePricingCardData?.PricingType) }} className=" m-[2px] w-full z-10 font-body font-medium  text-sm md:text-[16px] lg:py-3 lg:px-5  hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577]   px-4 py-4 rounded-md text-white hover:border-transparent " data-aos="zoom-in">{SinglePricingCardData?.ButtonText}</button>
      {/* </a> */}
    </div>
  )
}

export default SinglePricingCard
