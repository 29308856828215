import {
  ABOUTPAGE,
  CONNECTCARD,
  CONTACTPAGE,
  FEATURESPAGE,
  FOOTER,
  HOMEPAGE,
  MODULESLIST,
  OFFERINGPAGE,
  LANDINGPAGE
} from "./actions";

const initialState = {
  HomePageContent: {},
  FeaturesPageContent: {},
  AboutPageContent: {},
  ContactPageContent: {},
  ConnectCardContent: {},
  ModuleListContent: [],
  OfferingPageContent: {},
  FooterContent:{},
  LandingPageContent: {},

};

export function MainReducer(state = initialState, action: any) {
  switch (action.type) {
    
    case HOMEPAGE:
      return { ...state, HomePageContent:action?.payload };
    case FEATURESPAGE:
      return { ...state, FeaturesPageContent:action?.payload };
    case ABOUTPAGE:
      return { ...state, AboutPageContent: action?.payload };
    case CONNECTCARD:
      return { ...state, ConnectCardContent: action?.payload };
    case CONTACTPAGE:
      return { ...state, ContactPageContent: action?.payload };
    case OFFERINGPAGE:
      return { ...state, OfferingPageContent: action?.payload };
    case MODULESLIST:
      return { ...state, ModuleListContent: action?.payload };
    case FOOTER:
      return { ...state, FooterContent: action?.payload };
    case LANDINGPAGE:
      return {...state,LandingPageContent:action?.payload}


    default:
      return state;
  }
}
