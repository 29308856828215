export const HOMEPAGE="HOMEPAGE"
export const FEATURESPAGE="FEATURESPAGE"
export const MODULESLIST="MODULESLIST"
export const CONNECTCARD="CONNECTCARD"
export const OFFERINGPAGE="OFFERINGPAGE"
export const CONTACTPAGE="CONTACTPAGE"
export const ABOUTPAGE="ABOUTPAGE"
export const FOOTER="FOOTER"
export const LANDINGPAGE="LANDINGPAGE"



export const HomePageAction = (data: any) => {
    return {
      type: HOMEPAGE,
      payload: data,
    };
  };

  export const FeaturesPageAction = (data: any) => {
    return {
      type: FEATURESPAGE,
      payload: data,
    };
  };
  
  export const ModuleListAction = (data: any) => {
    return {
      type: MODULESLIST,
      payload: data,
    };
  };

  export const ConnectCardAction = (data: any) => {
    return {
      type: CONNECTCARD,
      payload: data,
    };
  };


  export const OfferingPageAction = (data: any) => {
    return {
      type: OFFERINGPAGE,
      payload: data,
    };
  };

  export const ContactPageAction = (data: any) => {
    return {
      type: CONTACTPAGE,
      payload: data,
    };
  };

  export const AboutPageAction = (data: any) => {
    return {
      type: ABOUTPAGE,
      payload: data,
    };
  };

  export const FooterAction = (data: any) => {
    return {
      type: FOOTER,
      payload: data,
    };
  };

  export const LandingPageAction = (data: any) => {
    return {
      type: LANDINGPAGE,
      payload: data,
    };
  };



  