import React from 'react'

function Star(props: any) {
    const { index, maxNumber } = props;
    console.log("maxnumber", index, maxNumber)
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0.282227L9.68386 5.4646H15.1329L10.7245 8.66748L12.4084 13.8499L8 10.647L3.59161 13.8499L5.27547 8.66748L0.867076 5.4646H6.31614L8 0.282227Z" fill={`${index <= maxNumber ? "#E5DF65" : "#D9D9D9"}`} />
        </svg>
    )
}

export default Star