import React from 'react'
import { Link } from 'react-router-dom';
import { env } from '../config';
import { DownWave, FeaturesPageTitleVector } from './SVGicons'

function FeaturesPageTitle(props: any) {
  const { FeaturesPageFaceContent } = props;
  return (
    <div>
      <div className=' pt-20 lg:pt-60 text-[#1F3934] lg:pl-24 bg-[#EAEAE0] flex flex-col-reverse lg:flex-row justify-between' data-aos="fade-down">
        <div className=' = w-fit px-10 py-5 flex flex-col '>
          <div className='text-[28px]  md:text-[36px] lg:text-[48px] font-extrabold  py-4 md:w-4/5 ' data-aos="fade-down">{FeaturesPageFaceContent?.FeaturesPageFaceTitle}</div>
          <div className='py-4 text-[16px] lg:text-[20px] md:w-3/5' data-aos="fade-down">{FeaturesPageFaceContent?.FeaturesPageFaceDescription1}</div>
          <div className='py-4 text-[16px] lg:text-[20px] w-3/5' data-aos="fade-down">{FeaturesPageFaceContent?.FeaturesPageFaceDescription2}</div>
          <Link to='https://hrmnest.com/app/' target="_blank">

            <button className='hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577] px-5 py-3 rounded-md text-white mr-4  mt-5 w-fit' data-aos="zoom-in">{FeaturesPageFaceContent?.FeaturesPageFaceButtonText}</button>
          </Link>
        </div>
        <div className=' w-1/2 mb-3 p-2 mx-auto flex items-center'>
          <div className=' mx-auto w-fit ' data-aos="zoom-in">
            {/* <FeaturesPageTitleVector/> */}
            <img className='xl:scale-[100%]' src={`${env}${FeaturesPageFaceContent?.FeaturePageFaceSVGImage?.data?.attributes?.url}`} alt={"feature title svg"} />

          </div>
        </div>
      </div>
      <div data-aos="fade-down">
        <DownWave background="#EAEAE0" />
      </div>

    </div>
  )
}

export default FeaturesPageTitle