import React from 'react'
import TestimonialCard from './TestimonialCard'
import { Link } from 'react-router-dom'

function LandingPageTestimonialSection(props: any) {
    const { TestimonialSection } = props
    return (
        <div className='px-3 lg:px-10  pt-10  md:py-10 mx-auto  xl:w-5/6 '>
            <div className='text-[36px] md:text-[48px] text-[#1F3934] font-extrabold mx-auto lg:w-max flex flex-col items-center justify-center'>
                <div className='xl:w-[70%] text-center'>
                    {TestimonialSection?.MainTitle}
                </div>
                <div className='px-3 py-1 rounded-md text-base w-fit my-5 bg-[#E67B25] font-bold '>
                    {TestimonialSection?.TagText}
                </div>
            </div>
            <div className='sliderContainer lg:h-[450px] xl:h-[450px] sm:w-[60%] mx-auto lg:w-auto flex flex-col lg:flex lg:flex-row gap-[4%] my-5'>
                {TestimonialSection?.TestimonyCollection?.map((node: any, index: number) => {
                    return (
                        <TestimonialCard key={node?.Title} node={node} index={index} />
                    )
                })}
            </div>

            <div className='text-[36px] md:text-[48px] my-[8%] text-[#1F3934] text-center font-extrabold mx-auto flex flex-col items-center justify-center'>
                {TestimonialSection?.SecondaryTitle}
                <div className='text-base font-normal my-[2%] w-3/4 text-center'>
                    {TestimonialSection?.SecondaryDescription}
                </div>
                <Link to='https://hrmnest.com/app/' target="_blank">
                    <button className='hover:bg-[#03776B] text-base font-normal active:bg-[#024F46] bg-[#038577] px-3 py-2 rounded-md text-white sm:w-max '>
                        {TestimonialSection?.ButtonText}
                    </button>
                </Link>
            </div>

        </div>
    )
}

export default LandingPageTestimonialSection