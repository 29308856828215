import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../redux/actions'
import axios from "axios";
import { env } from "../config";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OfferingPageTitle from "../components/OfferingPageTitle";
import { SmallLinesinFeatureMainTitle } from "../components/SVGicons";
import SinglePricingCard from "../components/SinglePricingCard";
import ConnectCard from "../components/ConnectCard";
import OfferingPagePricingContent from "../components/OfferingPagePricingContent";
import OfferingPageOpenSourceContent from "../components/OfferingPageOpenSourceContent";
import OfferingPageDeveloperConnect from "../components/OfferingPageDeveloperConnect";
import Loader from "../components/Loader";
import useSendRoute from "../components/useSendRoute";

function OfferingPage() {
  const dispatch = useDispatch()
  const OfferingData: any = useSelector(
    (state: any) => state?.DataReducer?.OfferingPageContent
  );
  console.log("OFFERINGDATA", OfferingData)
  useSendRoute()
  useEffect(() => {

    setTimeout(() => {

      (async () => {
        await axios
          .get(`${env}/api/offering-page-content?populate=deep`)
          .then(async (response: any) => {
            dispatch(Actions.OfferingPageAction(response?.data?.data?.attributes));
            // console.log("OFFERINGDATA", response?.data?.data?.attributes);
          })
          .catch((error: any) => {
            console.log("error", error);
          });
      })()

    }, 0);




  }, [dispatch])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>{
      OfferingData?.OfferingPageTitleContent ?
        <>
          <Header background="#EAEAE0" activePage="offerings" />
          <OfferingPageTitle OfferingData={OfferingData} />
          <OfferingPagePricingContent OfferingPagePricingContent={OfferingData?.OfferingPagePricingContent} OfferingPagePricingCards={OfferingData?.OfferingPagePricingCards} />
          {/* <OfferingPageOpenSourceContent OfferingPageOpenSourceContent={OfferingData?.OfferingPageOpenSourceContent} /> */}
          <OfferingPageDeveloperConnect OfferingPageDeveloperConnectContent={OfferingData?.OfferingPageDeveloperConnectContent} />
          <ConnectCard />
          <Footer background="#FDF4ED" wave={true} />
        </> :
        <Loader background={"#EAEAE0"} />
    }

    </>
  )
    ;
}

export default OfferingPage;
