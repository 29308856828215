import React from 'react'
import { Link } from 'react-router-dom';
import { env } from '../config';
import { LogGoogleAnalyticsEvent } from './logGoogleAnalyticsEvent';

function LandingPageKnowMoreCard(props: any) {
    const { background, KnowMoreCard } = props;
    return (
        <div className={`pb-10 lg:py-20 px-5 lg:px-20   w-full bg-[${background}]`} >
            <div className='relative flex flex-col my-10 BoxShadow rounded-[20px] xl:w-[85%] xl:mx-auto lg:py-8 py-4 px-5 lg:px-10 bg-white  h-[250px] xl:h-[350px] '>
                <svg data-aos="zoom-in" className='absolute z-[1] top-0 left-0' width="233" height="175" viewBox="0 0 233 175" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M233 0.782227C233 96.8798 155.098 174.782 59 174.782H0V0.782227H233Z" fill="#D1E9E6" />
                </svg>
                <svg data-aos="zoom-in" className='absolute bottom-0 right-0 z-[1]' width="151" height="179" viewBox="0 0 151 179" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 151.31C0 67.9146 67.605 0.30957 151 0.30957V178.31H0V151.31Z" fill="#D1E9E6" />
                </svg>
                <div className=' z-[2] h-full xl:h-full xl:w-[40%] flex flex-col justify-evenly'>
                    <div className='px-3 py-1 rounded-md  w-fit my-1 bg-[#E67B25] font-bold' data-aos="fade-in">{KnowMoreCard?.TagText}</div>
                    <div className=' py-1 md:py-10 my-1 text-2xl md:text-3xl xl:text-[45px]   font-extrabold lg:w-max' data-aos="fade-in">{KnowMoreCard?.Title}</div>

                    <div className='w-2/3 flex flex-col justify-start'>
                        <Link to='/contact-us' >
                            <button onClick={(e) => LogGoogleAnalyticsEvent(e)} className='bg-transparent px-5 py-3  w-max  border-[#038577] border text-[14px] md:text-[16px] rounded-md hover:text-white hover:bg-[#03776B]    active:bg-[#024F46]' data-aos="zoom-in">{KnowMoreCard?.ButtonText}</button>
                        </Link>
                    </div>
                </div>
                <img data-aos="zoom-in" className='absolute hidden md:block z-[9] left-[60%] -top-[10%] ' src={`${env}${KnowMoreCard?.Image?.data?.attributes?.url}`} alt="PhoneScreen" />

            </div>
        </div>
    )
}

export default LandingPageKnowMoreCard