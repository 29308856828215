import React from 'react'
import { SmallLinesinFeatureMainTitle } from './SVGicons'

function Loader(props:any) {
  return (
    <div className={`w-[100vw] h-[100vh] bg-[${props.background}] flex justify-center items-center `}>
        <div className='p-2 relative z-[1] flex justify-center items-center'>
        <div className='spinner z-[3] animate-spin absolute w-[47px] h-[47px] lg:w-[67px] lg:h-[67px] bg-transparent flex justify-center border-[3px] lg:border-[5px]  rounded-full items-center' >
        </div>

        
        <svg className="scale-[80%] lg:scale-[100%] absolute" width="36" height="16" viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.588867 0.0819092C5.94601 3.65334 20.4103 8.65334 35.4103 0.0819092C29.4579 6.92715 14.1603 16.5105 0.588867 0.0819092Z" fill="#038577"/>
         <path d="M6.065 6.64033C8.85973 9.38012 17.4657 12.9441 27.5377 9.88023C23.1243 13.0868 12.252 17.8477 6.065 6.64033Z" fill="#038577"/>
        </svg>
        </div>
        

    </div>
  )
}

export default Loader