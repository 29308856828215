import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContactForm from "../components/ContactForm";
import ContactPageTitle from "../components/ContactPageTitle";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { DownWave } from "../components/SVGicons";
import { env } from "../config";
import * as Actions from "../redux/actions";
import useSendRoute from "../components/useSendRoute";

function ContactPage() {

  const dispatch = useDispatch()
  const ContactData: any = useSelector(
    (state: any) => state?.DataReducer?.ContactPageContent
  );
  useSendRoute()
  useEffect(() => {

    setTimeout(() => {
      (async () => {
        await axios
          .get(`${env}/api/contact-page-content?populate=deep`)
          .then(async (response: any) => {
            dispatch(Actions.ContactPageAction(response?.data?.data?.attributes));
            // console.log("ContactDATA", response?.data?.data?.attributes);
          })
          .catch((error: any) => {
            console.log("error", error);
          });

      })()

    }, 0);


  }, [dispatch])



  // console.log("CONTACTDATA",ContactData)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <>
      {
        ContactData?.ContactPageTitleContent?.ContactPageTitleSVGImage?.data?.attributes?.url ?
          <>
            <Header background={"white"} activePage="contact-us" />
            <ContactPageTitle ContactPageTitleContent={ContactData?.ContactPageTitleContent} />
            <ContactForm ContactData={ContactData} />
            <Footer background="#FDF4ED" wave={true} />
          </> :
          <Loader background={"white"} />

      }


    </>
  )
}

export default ContactPage;
