import React from 'react'
import { env } from '../config';
import { DownWave } from './SVGicons';

function ModulesPageTitleSection(props: any) {
  const { TitleData } = props;
  return (
    <div>
      <div className='pt-20 lg:pt-60 pb-20 text-[#1F3934]  lg:px-28 bg-[#EAEAE0] flex flex-col-reverse lg:flex-row justify-between' data-aos="fade-down">
        <div className='lg:w-fit px-5 md:pr-10 md:pl-0  lg:py-5 flex flex-col'>
          <div className='text-[36px] md:text-[48px] font-extrabold py-4 lg:w-4/5' >{TitleData.ModuleMainHeading}</div>
          <div className='lg:py-4 text-[16px] md:text-[20px] w-full lg:w-4/5' >{TitleData.ModuleMainDescription}</div>
          {TitleData?.ModuleSubDescription ?
            <div className='lg:py-4 text-[16px] md:text-[20px] w-full lg:w-4/5' >{TitleData.ModuleSubDescription}</div>
            : null}

        </div>
        <div data-aos="zoom-in" className=' flex justify-center items-center '>
          <img className=" scale-[90%] md:scale-[100%] lg:scale-[160%] flex justify-center items-center" src={`${env}${TitleData?.ModuleTitleSVGImage?.data?.attributes?.url}`}
          ></img>
          {/* <OfferingPageTitleSVG/> */}
        </div>
      </div>
      <div data-aos="fade-down">
        <DownWave background="#EAEAE0" />
      </div>
    </div>
  )
}

export default ModulesPageTitleSection