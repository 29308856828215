import React from 'react'
import { env } from '../config';

function ContactPageTitle(props:any) {
    const {ContactPageTitleContent}=props;
  return (
    <div className="  pt-28 pb-5 md:px-20 " >
      <div className=" h-fit p-2 w-full mx-auto items-center flex flex-col-reverse md:flex-row justify-around" >
      <div className="flex flex-col  p-3 text-[#1F3934]">
        <div className="font-extrabold  text-center md:text-left text-[36px] md:text-[48px] my-3" data-aos="fade-down">{ContactPageTitleContent?.ContactPageTitleText}</div>
        <div data-aos="fade-down " className='text-[16px] md:text-[20px]'>{ContactPageTitleContent?.ContactPageDescriptionText}</div>
      </div>
      <div data-aos="zoom-in" className='px-2 md:px-0'>
          <img className='xl:scale-[150%]' src={`${env}${ContactPageTitleContent?.ContactPageTitleSVGImage?.data?.attributes?.url}`}></img>
      </div>
      </div>
    </div>
  )
}

export default ContactPageTitle