import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import ReactGA from "react-ga4";

function useSendRoute() {
    const location = useLocation()

    useEffect(() => {
        return ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
    }, [])



}

export default useSendRoute