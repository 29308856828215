import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import React from 'react'
import SinglePricingCard from './SinglePricingCard'

function OfferingPagePricingContent(props: any) {


  const { OfferingPagePricingContent, OfferingPagePricingCards } = props;
  console.log("OfferingPagePricingContent?.OfferingPagePricingDescription3", OfferingPagePricingContent?.OfferingPagePricingDescription3)
  return (
    <div className="px-3 lg:px-20  pt-10  md:py-10  " >
      <div className="  mx-auto   flex flex-col p-1  xl:w-5/6">
        <div className="  my-2 font-extrabold text-[36px] lg:text-[48px] text-center w-5/6  mx-auto " data-aos="fade-up">{OfferingPagePricingContent?.OfferingPagePricingTitle}</div>
        <div className=" my-2 text-center text-[16px] md:text-[20px] mx-auto w-5/6" data-aos="fade-up" >
          {OfferingPagePricingContent?.OfferingPagePricingDescription1}
        </div>
        <div className=" my-2 text-center text-[16px] md:text-[20px]" data-aos="fade-up">
          {OfferingPagePricingContent?.OfferingPagePricingDescription2}
        </div>

        <div className='flex flex-col items-center text-center text-[16px] md:text-[20px] '>
          <div id="developerConnect" className="px-3 py-1 rounded-md mx-auto w-fit my-5 bg-[#E67B25] " data-aos="fade-up">{OfferingPagePricingContent?.OfferingPagePricingtagText}</div>
          <div className=" md:my-2 text-center text-[16px] md:text-[20px] xl:w-[60%] lg:mx-auto" data-aos="fade-up">
            <div
              dangerouslySetInnerHTML={{ __html: OfferingPagePricingContent?.OfferingPagePricingDescription3 }}
            />
          </div>
          <div className=" md:flex flex-row justify-around my-5 w-full xl:w-[85%] mx-auto  p-2  ">
            {
              OfferingPagePricingCards?.map((node: any) => (
                <SinglePricingCard key={node.PricingType} SinglePricingCardData={node} />
              ))
            }
          </div>
        </div>
      </div>
    </div>

  )
}

export default OfferingPagePricingContent

