import React from "react";
import { env } from "../config";
import { DownWave, IrregularCard, OfferingPageTitleSVG } from "./SVGicons";

function OfferingPageTitle(props: any) {
  const { OfferingData } = props;
  console.log("OfferingData", OfferingData)

  const scrollIntoView = () => {
    const section: any = document.querySelector('#developerConnect');
    console.log("oisdjoijsa", section)

    section.scrollIntoView({ behavior: 'smooth' });


  }
  return (
    <>
      <div className=" bg-[#EAEAE0] pt-5 lg:pt-28 pb-20 md:px-10 flex flex-col ">
        <div className=" h-fit p-2 w-full mx-auto items-center flex flex-col-reverse  lg:flex-row justify-around">
          <div className="flex flex-col  p-3 text-[#1F3934] lg:w-[50%] xl:w-[50%] ">
            <div
              className="font-extrabold text-[36px] md:text-[48px] my-4 lg:w-full xl:w-[80%] "
              data-aos="fade-down"
            >
              {OfferingData?.OfferingPageTitleContent?.OfferingPageTitleText}
            </div>
            <div className=" text-[16px] md:text-[20px] my-4 lg:w-full  xl:w-[80%]" data-aos="fade-up">
              {
                OfferingData?.OfferingPageTitleContent
                  ?.OfferingPageTitleDescription1
              }
            </div>
            <div className=" text-[16px] md:text-[20px]  my-4  lg:w-full xl:w-[80%]" data-aos="fade-up">
              {
                OfferingData?.OfferingPageTitleContent
                  ?.OfferingPageTitleDescription2
              }
            </div>
          </div>

          <div data-aos="zoom-in">
            <img
              alt={OfferingData?.OfferingPageTitleContent?.OfferingPageTitleText + "title"}
              className="scale-[70%]  lg:scale-[100%] xl:scale-[130%]"
              src={`${env}${OfferingData?.OfferingPageTitleContent?.OfferingPageTitleSVGImage?.data?.attributes?.url}`}
            ></img>
            {/* <OfferingPageTitleSVG/> */}
          </div>
        </div>
        <div
          className=" hidden md:flex text-[22px] md:text-[24px] font-bold mx-auto  my-24  "
          data-aos="fade-down"
        >
          {OfferingData?.OfferingPageTitleContent?.OfferingPageTitleMenuText}
        </div>
        <div className="hidden md:flex flex-row w-full lg:w-[90%] justify-center gap-[20%] mx-auto ">

          <div
            data-aos="zoom-in"
            className="IrregularCard z-[1] relative flex items-center justify-center "
            onClick={() => window.scrollTo(0, 1100)}
          // className="font-bold cursor-pointer w-[300px] h-[300px] text-[16px] bg-white BoxShadow border-2 rounded-full  border-[#038577] hover:border-[#E67B25] flex justify-center items-center flex-col"
          >
            <IrregularCard />
            <div className="  absolute z-[10] flex items-center flex-col  cursor-pointer scale-[60%] lg:scale[100%] xl:scale-[103%]">
              <div >
                <img
                  alt={
                    OfferingData?.OfferingPagePricingContent
                      ?.OfferingPagePricingTitle
                  }
                  className="scale-[100%]"
                  src={`${env}${OfferingData?.OfferingPagePricingContent?.OfferingPagePricingMenuSVGImage?.data?.attributes?.url}`}
                />
              </div>
              <div className="font-bold cursor-pointer text-[20px] lg:text-[25px] " >
                {" "}
                {
                  OfferingData?.OfferingPagePricingContent
                    ?.OfferingPagePricingTitle
                }
              </div>
            </div>
          </div>

          {/* <div
              data-aos="zoom-in"
              className="IrregularCard z-[1] relative flex items-center justify-center"
              onClick={() => window.scrollTo(0, 1900)}
            // className="font-bold cursor-pointer w-[300px] h-[300px] text-[16px] bg-white BoxShadow border-2 rounded-full  border-[#038577] hover:border-[#E67B25] flex justify-center items-center flex-col"
            >
              <IrregularCard />
              <div className="absolute z-[10] flex items-center flex-col cursor-pointer scale-[60%] lg:scale[100%] xl:scale-[103%]">
                <div >
                  <img
                    className="scale-[100%]"
                    src={`${env}${OfferingData?.OfferingPageOpenSourceContent?.OfferingPageMenuSVGImage?.data?.attributes?.url}`} />
                </div>
                <div className="font-bold cursor-pointer text-[20px] lg:text-[25px]" >
                  {" "}
                  {
                    OfferingData?.OfferingPageOpenSourceContent
                      ?.OfferingPageOpenSourceTitle
                  }
                </div>
              </div>
            </div> */}

          <div
            data-aos="zoom-in"
            className=" IrregularCard z-[1] relative flex items-center justify-center "
            onClick={scrollIntoView}
          // className="font-bold cursor-pointer w-[300px] h-[300px] text-[16px] bg-white BoxShadow border-2 rounded-full  border-[#038577] hover:border-[#E67B25] flex justify-center items-center flex-col"
          >
            <IrregularCard />
            <div className=" absolute z-[10] flex items-center flex-col cursor-pointer scale-[60%] lg:scale[100%] xl:scale-[103%]">
              <div >
                <img
                  alt={
                    OfferingData?.OfferingPageDeveloperConnectContent
                      ?.OfferingPageDeveloperConnectTitle
                  }
                  className="scale-[100%]"
                  src={`${env}${OfferingData?.OfferingPageDeveloperConnectContent?.OfferingPageDeveloperConnectMenuSVGImage?.data?.attributes?.url}`}
                />
              </div>
              <div className="font-bold cursor-pointer text-[20px] lg:text-[25px] " >
                {" "}
                {
                  OfferingData?.OfferingPageDeveloperConnectContent
                    ?.OfferingPageDeveloperConnectTitle
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <DownWave background="#EAEAE0" />
    </>
  );
}

export default OfferingPageTitle;
