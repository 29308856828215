import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LOGOSVG } from './SVGicons';
import ReactGA from "react-ga4";



function Header(props: any) {

  const [dropDownFlag, setDropDownFlag] = useState(false);
  const [burgerDropDownFlag, setBurgerDropDownFlag] = useState(false);
  const { background, activePage } = props;

  return (
    <nav>
      <div className={`flex flex-row w-full h-12 md:h-16 fixed bg-[${background}]  z-10  select-none  `} >
        <div className="flex w-full  float-left  items-center justify-between xl:justify-around px-5 lg:mx-[8%] lg:px-0  ">
          <div className='flex md:hidden  fle justify-center items-center' onClick={() => setBurgerDropDownFlag(!burgerDropDownFlag)}>
            {burgerDropDownFlag ?
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 650.088 293.044 837.044Q278.087 852.001 256 852.001q-22.087 0-37.044-14.957-14.957-14.957-14.957-37.044 0-22.087 14.957-37.044L405.912 576 218.956 389.044Q203.999 374.087 203.999 352q0-22.087 14.957-37.044 14.957-14.957 37.044-14.957 22.087 0 37.044 14.957L480 501.912l186.956-186.956q14.957-14.957 37.044-14.957 22.087 0 37.044 14.957 14.957 14.957 14.957 37.044 0 22.087-14.957 37.044L554.088 576l186.956 186.956q14.957 14.957 14.957 37.044 0 22.087-14.957 37.044-14.957 14.957-37.044 14.957-22.087 0-37.044-14.957L480 650.088Z" /></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M160 837.479q-22.087 0-37.544-15.456-15.457-15.457-15.457-37.544 0-22.088 15.457-37.544 15.457-15.457 37.544-15.457h640q22.087 0 37.544 15.457 15.457 15.456 15.457 37.544 0 22.087-15.457 37.544-15.457 15.456-37.544 15.456H160Zm0-208.478q-22.087 0-37.544-15.457-15.457-15.457-15.457-37.544 0-22.087 15.457-37.544 15.457-15.457 37.544-15.457h640q22.087 0 37.544 15.457 15.457 15.457 15.457 37.544 0 22.087-15.457 37.544-15.457 15.457-37.544 15.457H160Zm0-208.479q-22.087 0-37.544-15.457-15.457-15.456-15.457-37.544 0-22.087 15.457-37.544 15.457-15.456 37.544-15.456h640q22.087 0 37.544 15.456 15.457 15.457 15.457 37.544 0 22.088-15.457 37.544-15.457 15.457-37.544 15.457H160Z" /></svg>
            }
          </div>
          <div className="md:p-1  flex flex-row float-left md:w-auto mr-auto item-center ">
            <div className=" mx-auto  text-stone-600 md:p-4 flex  justify-center items-center md:mx-2  ">
              <Link to={"/"} aria-label="Go To Homepage" className="scale-[60%] md:scale-[80%] lg:scale-[90%] xl:scale-[100%]">
                {/* <img src={"./Hoolva-logo.png"} alt="logo" /> */}
                <LOGOSVG />
              </Link>
            </div>
          </div>
          <div className=" hidden mx-auto mr-[38%] md:flex md:justify-evenly items-center md:p-3 w-[50%] lg:w-[70%] xl:w-[35%] cursor-pointer font-normal text-[#1F3934] text-[14px] lg:text-[16px]  ">

            <Link to="/features">
              <div
                className={`m-1 font-body hover:text-[#028577]   mx-3 `}
                //   id={activePage === "solution" ? "header-gradient-text" : ""}
                style={{
                  color: `${activePage === "features" ? "#028577" : ""}`,
                  fontWeight: `${activePage === "features" ? "bold" : ""}`

                }}
              >
                Features
              </div>
            </Link>
            <div
              onClick={() => setDropDownFlag(!dropDownFlag)}
              className="m-1 font-body  flex flex-row items-center hover:text-[#028577]  justify-around mx-3"
              style={{ color: `${activePage === "modules" ? "#028577" : ""}`, fontWeight: `${activePage === "modules" ? "bold" : ""}` }}
            >
              <div>Modules</div>
            </div>


            <Link to="/offerings">
              <div
                className={"m-1 font-body hover:text-[#028577]  mx-2"}
                style={{ color: `${activePage === "offerings" ? "#028577" : ""}`, fontWeight: `${activePage === "offerings" ? "bold" : ""}` }}
              //   id={activePage === "partnership" ? "header-gradient-text" : ""}
              >
                Offerings
              </div>
            </Link>




            {/* <a href='https://dev.hrmnest.com' target={"_blank"}>
              <div
                className={"m-1 font-body hover:text-[#028577]  mx-2"}
                style={{ color: `${activePage === "developer-portal" ? "#028577" : ""}`, fontWeight: `${activePage === "developer-portal" ? "bold" : ""}` }}
              //   id={activePage === "partnership" ? "header-gradient-text" : ""}
              >
                Developer Portal
              </div>
            </a> */}


            {/* <Link to="/contact-us">
            <div
              className={"m-1 font-body hover:text-[#028577]  mx-2"}
              style=
              {{ color:`${activePage==="contact-us"?"#028577":""}`,fontWeight:`${activePage==="contact-us"?"bold":""}`
                    
            }}
            >
              Contact Us
            </div>
          </Link> */}


          </div>
          <Link to='https://hrmnest.com/app' target="_blank">
            <div className='hidden md:flex'>
              <button onClick={() => {
                ReactGA.event({
                  category: 'Sign-In',
                  action: 'signIn',
                  label: 'Header Sign In Button'
                });
              }} className='hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577]  px-3 py-2 rounded-md text-white   sm:w-max   ' data-aos="zoom-in">Sign In</button>
            </div>
          </Link>

        </div>
      </div>

      {dropDownFlag ? (
        <div className={`py-1 bg-white flex-col fixed text-[#686868] rounded-md mt-[70px]  md:ml-[30%] lg:ml-[30%] xl:ml-[38%] mx-auto shadow-sm z-[5] hidden md:flex`} onMouseLeave={() => setDropDownFlag(false)}>
          <Link to="/modules/people-management">
            <div
              className={"m-1 font-body   mx-2 flex flex-row hover:bg-[#D1E9E6] px-2 py-1 rounded-[6px] item-center"}
            // style={{ color:`${activePage==="conatact-us"?"#028577":""}`}}
            //   id={activePage === "partnership" ? "header-gradient-text" : ""}
            >
              <div className='flex items-center mr-2 w-[30px] justify-center '>
                <svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.55 5.95L5.275 4.2L3.55 2.475L1.8 4.2L3.55 5.95ZM16.725 5.65L18.5 2.8L20.3 5.65H16.725ZM11 5C10.4 5 9.88333 4.78767 9.45 4.363C9.01667 3.93767 8.8 3.41667 8.8 2.8C8.8 2.18333 9.01267 1.66667 9.438 1.25C9.86267 0.833333 10.3833 0.625 11 0.625C11.6167 0.625 12.1373 0.833333 12.562 1.25C12.9873 1.66667 13.2 2.18333 13.2 2.8C13.2 3.4 12.9873 3.91667 12.562 4.35C12.1373 4.78333 11.6167 5 11 5ZM11 1.625C10.6833 1.625 10.4043 1.73733 10.163 1.962C9.921 2.18733 9.8 2.46667 9.8 2.8C9.8 3.13333 9.921 3.41667 10.163 3.65C10.4043 3.88333 10.6833 4 11 4C11.3333 4 11.6167 3.88333 11.85 3.65C12.0833 3.41667 12.2 3.13333 12.2 2.8C12.2 2.46667 12.0833 2.18733 11.85 1.962C11.6167 1.73733 11.3333 1.625 11 1.625ZM0 9.575V8.725C0 8.09167 0.325 7.575 0.975 7.175C1.625 6.775 2.475 6.575 3.525 6.575C3.70833 6.575 3.88733 6.58333 4.062 6.6C4.23733 6.61667 4.40833 6.64167 4.575 6.675C4.425 6.925 4.31267 7.19167 4.238 7.475C4.16267 7.75833 4.125 8.05833 4.125 8.375V9.575H0ZM6 9.575V8.45C6 7.61667 6.46233 6.95833 7.387 6.475C8.31233 5.99167 9.51667 5.75 11 5.75C12.5 5.75 13.7083 5.99167 14.625 6.475C15.5417 6.95833 16 7.61667 16 8.45V9.575H6ZM18.5 6.575C19.55 6.575 20.396 6.775 21.038 7.175C21.6793 7.575 22 8.09167 22 8.725V9.575H17.875V8.375C17.875 8.05833 17.8417 7.75833 17.775 7.475C17.7083 7.19167 17.6083 6.925 17.475 6.675C17.625 6.64167 17.7873 6.61667 17.962 6.6C18.1373 6.58333 18.3167 6.575 18.5 6.575ZM11 6.75C9.86667 6.75 8.92933 6.9 8.188 7.2C7.446 7.5 7.05833 7.89167 7.025 8.375V8.575H14.975V8.375C14.9417 7.89167 14.5583 7.5 13.825 7.2C13.0917 6.9 12.15 6.75 11 6.75Z" fill="#025C53" />
                </svg>
              </div>
              <div>People Management</div>
            </div>
          </Link>

          <Link to="/modules/leave-builder">
            <div
              className={"m-1 font-body   mx-2   flex flex-row hover:bg-[#D1E9E6] px-2 py-1 rounded-[6px] item-center"}
            // style={{ color:`${activePage==="conatact-us"?"#028577":""}`}}
            //   id={activePage === "partnership" ? "header-gradient-text" : ""}
            >
              <div className='flex items-center mr-2 w-[30px] justify-center '><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 6H12.625V5H8V6ZM8 11H12.625V10H8V11ZM5 6.725C5.35 6.725 5.64167 6.60833 5.875 6.375C6.10833 6.14167 6.225 5.85 6.225 5.5C6.225 5.15 6.10833 4.85833 5.875 4.625C5.64167 4.39167 5.35 4.275 5 4.275C4.65 4.275 4.35833 4.39167 4.125 4.625C3.89167 4.85833 3.775 5.15 3.775 5.5C3.775 5.85 3.89167 6.14167 4.125 6.375C4.35833 6.60833 4.65 6.725 5 6.725ZM5 11.725C5.35 11.725 5.64167 11.6083 5.875 11.375C6.10833 11.1417 6.225 10.85 6.225 10.5C6.225 10.15 6.10833 9.85833 5.875 9.625C5.64167 9.39167 5.35 9.275 5 9.275C4.65 9.275 4.35833 9.39167 4.125 9.625C3.89167 9.85833 3.775 10.15 3.775 10.5C3.775 10.85 3.89167 11.1417 4.125 11.375C4.35833 11.6083 4.65 11.725 5 11.725ZM1.625 16C1.15833 16 0.771 15.846 0.463 15.538C0.154333 15.2293 0 14.8417 0 14.375V1.625C0 1.15833 0.154333 0.771 0.463 0.463C0.771 0.154333 1.15833 0 1.625 0H14.375C14.8417 0 15.2293 0.154333 15.538 0.463C15.846 0.771 16 1.15833 16 1.625V14.375C16 14.8417 15.846 15.2293 15.538 15.538C15.2293 15.846 14.8417 16 14.375 16H1.625ZM1.625 15H14.375C14.5417 15 14.6873 14.9373 14.812 14.812C14.9373 14.6873 15 14.5417 15 14.375V1.625C15 1.45833 14.9373 1.31267 14.812 1.188C14.6873 1.06267 14.5417 1 14.375 1H1.625C1.45833 1 1.31267 1.06267 1.188 1.188C1.06267 1.31267 1 1.45833 1 1.625V14.375C1 14.5417 1.06267 14.6873 1.188 14.812C1.31267 14.9373 1.45833 15 1.625 15Z" fill="#025C53" />
              </svg>
              </div>
              <div>Leave Builder</div>

            </div>
          </Link>

          <Link to="/modules/organization-operation">
            <div
              className={"m-1 font-body   mx-2  flex flex-row hover:bg-[#D1E9E6] px-2 py-1 rounded-[6px] item-center"}
            // style={{ color:`${activePage==="conatact-us"?"#028577":""}`}}
            //   id={activePage === "partnership" ? "header-gradient-text" : ""}
            >
              <div className=' flex items-center mr-2 w-[30px] justify-center '>
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.725006 17.15V0.150024H9.72501V4.15002H19.275V17.15H0.725006ZM1.72501 16.15H8.72501V13.15H1.72501V16.15ZM1.72501 12.15H8.72501V9.15002H1.72501V12.15ZM1.72501 8.15002H8.72501V5.15002H1.72501V8.15002ZM1.72501 4.15002H8.72501V1.15002H1.72501V4.15002ZM9.72501 16.15H18.275V5.15002H9.72501V16.15ZM12.125 9.15002V8.15002H15.5V9.15002H12.125ZM12.125 13.15V12.15H15.5V13.15H12.125Z" fill="#025C53" />
                </svg>
              </div>
              <div>Organization Operation</div>
            </div>
          </Link>

          <Link to="/modules/hiring-management">
            <div
              className={"m-1 font-body   mx-2   flex flex-row hover:bg-[#D1E9E6] px-2 py-1 rounded-[6px] item-center"}
            // style={{ color:`${activePage==="conatact-us"?"#028577":""}`}}
            //   id={activePage === "partnership" ? "header-gradient-text" : ""}
            >
              <div className='flex items-center mr-2 w-[30px] justify-center '><svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 6.375C6.16667 6.375 5.45833 6.08333 4.875 5.5C4.29167 4.91667 4 4.20833 4 3.375C4 2.55833 4.29167 1.854 4.875 1.262C5.45833 0.670667 6.16667 0.375 7 0.375C7.83333 0.375 8.54167 0.670667 9.125 1.262C9.70833 1.854 10 2.55833 10 3.375C10 4.20833 9.70833 4.91667 9.125 5.5C8.54167 6.08333 7.83333 6.375 7 6.375ZM7 5.375C7.55 5.375 8.021 5.179 8.413 4.787C8.80433 4.39567 9 3.925 9 3.375C9 2.825 8.80433 2.35433 8.413 1.963C8.021 1.571 7.55 1.375 7 1.375C6.45 1.375 5.97933 1.571 5.588 1.963C5.196 2.35433 5 2.825 5 3.375C5 3.925 5.196 4.39567 5.588 4.787C5.97933 5.179 6.45 5.375 7 5.375ZM17.225 16.925L14.55 14.25C14.2333 14.4833 13.9 14.6543 13.55 14.763C13.2 14.871 12.85 14.925 12.5 14.925C11.55 14.925 10.7417 14.5917 10.075 13.925C9.40833 13.2583 9.075 12.45 9.075 11.5C9.075 10.55 9.40833 9.74167 10.075 9.075C10.7417 8.40833 11.55 8.075 12.5 8.075C13.45 8.075 14.2583 8.40833 14.925 9.075C15.5917 9.74167 15.925 10.55 15.925 11.5C15.925 11.85 15.871 12.2 15.763 12.55C15.6543 12.9 15.4833 13.2333 15.25 13.55L17.925 16.225L17.225 16.925ZM12.5 13.925C13.1833 13.925 13.7583 13.6917 14.225 13.225C14.6917 12.7583 14.925 12.1833 14.925 11.5C14.925 10.8167 14.6917 10.2417 14.225 9.775C13.7583 9.30833 13.1833 9.075 12.5 9.075C11.8167 9.075 11.2417 9.30833 10.775 9.775C10.3083 10.2417 10.075 10.8167 10.075 11.5C10.075 12.1833 10.3083 12.7583 10.775 13.225C11.2417 13.6917 11.8167 13.925 12.5 13.925ZM0 13.625V11.975C0 11.5417 0.121 11.154 0.363 10.812C0.604333 10.4707 0.925 10.1917 1.325 9.975C2.05833 9.625 3 9.29167 4.15 8.975C5.3 8.65833 6.54167 8.55 7.875 8.65C7.775 8.81667 7.68767 8.979 7.613 9.137C7.53767 9.29567 7.475 9.46667 7.425 9.65C6.225 9.58333 5.121 9.7 4.113 10C3.10433 10.3 2.34167 10.5833 1.825 10.85C1.55833 10.9667 1.35433 11.125 1.213 11.325C1.071 11.525 1 11.7417 1 11.975V12.625H7.2C7.23333 12.775 7.27933 12.9377 7.338 13.113C7.396 13.2877 7.45833 13.4583 7.525 13.625H0Z" fill="#025C53" />
              </svg>
              </div>
              <div>Hiring Management</div>

            </div>
          </Link>
        </div>
      ) : null}

      {burgerDropDownFlag ? (
        <div className="bg-white md:hidden w-[300px]  text-left py-2 px-4 fixed ml-3 top-12 z-10 rounded-md shadow-md select-none">
          <div className="text-[17px] p-1  w-full  my-[2px] border-b border-gray-200">
            <Link
              to="/features"
              style={{ color: `${activePage === "features" ? "#028577" : ""}`, fontWeight: `${activePage === "features" ? "bold" : ""}` }}
            >
              Features

            </Link>
          </div>

          <div className="text-[17px] p-1  w-full  my-[2px] border-b border-gray-200" onClick={() => setDropDownFlag(!dropDownFlag)}
            style={{ color: `${activePage === "modules" ? "#028577" : ""}`, fontWeight: `${activePage === "modules" ? "bold" : ""}` }}

          >
            Modules
          </div>
          {dropDownFlag ?
            <div className='flex flex-col text-[17px]'>
              <Link to="/modules/people-management">
                <div
                  className={"m-1  text-[#1F3934]   mx-2 flex flex-row  px-2 py-1  item-center border-b border-gray-200"}

                >
                  <div>People Management</div>
                </div>
              </Link>
              <Link to="/modules/leave-builder">
                <div
                  className={"m-1  text-[#1F3934]   mx-2 flex flex-row  px-2 py-1  item-center border-b border-gray-200"}

                >
                  <div>Leave Builder</div>
                </div>
              </Link>
              <Link to="/modules/organization-operation">
                <div
                  className={"m-1  text-[#1F3934]  mx-2 flex flex-row  px-2 py-1  item-center border-b border-gray-200"}

                >
                  <div>Organization Operation</div>
                </div>
              </Link>
              <Link to="/modules/hiring-management">
                <div
                  className={"m-1  text-[#1F3934]  mx-2 flex flex-row  px-2 py-1  item-center border-b border-gray-200"}

                >
                  <div>Hiring Management</div>
                </div>
              </Link>

            </div>
            :
            null
          }
          <div className="text-[17px] p-1  w-full  my-[2px] border-b-0 border-gray-200">
            <Link
              to="/offerings"
              style={{ color: `${activePage === "offerings" ? "#028577" : ""}`, fontWeight: `${activePage === "offerings" ? "bold" : ""}` }}
            >
              Offerings
            </Link>
          </div>
          {/* <div className="text-[17px] p-1  w-full my-[2px] ">
            <a
              href='https://dev.hrmnest.com' target={"_blank"}
              style={{ color: `${activePage === "developer-portal" ? "#028577" : ""}`, fontWeight: `${activePage === "developer-portal" ? "bold" : ""}` }}
            >
              Developer Portal
            </a>
          </div> */}


          {/* <div className="text-[17px] p-1  w-full my-[2px] ">
            <a
              href='https://dev.hrmnest.com' target={"_blank"}
              style={{ color: `${activePage === "developer-portal" ? "#028577" : ""}`, fontWeight: `${activePage === "developer-portal" ? "bold" : ""}` }}
            >
              Developer Portal
            </a>
          </div> */}
          {/* <div className="text-[16px] p-1  w-full my-[2px] ">
          <Link
            to="/contact-us"
            style={{ color:`${activePage==="contact-us"?"#028577":""}`,fontWeight:`${activePage==="contact-us"?"bold":""}`}}
          >
            Contact Us
          </Link>
        </div> */}
        </div>
      ) : null}
    </nav>
  )
}

export default Header