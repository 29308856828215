import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import { ENV } from "../redux/actions";
import FooterLinkSection from "./FooterLinkSection";
import { LOGOSVG, UpWave } from "./SVGicons";
import {
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    TwitterIcon,
} from "./SVGicons";
import { LogGoogleAnalyticsEvent } from "./logGoogleAnalyticsEvent";

function LandingPageFooter(props: any) {
    const { wave, background } = props;

    const FooterData: any = useSelector(
        (state: any) => state?.DataReducer?.FooterContent
    );



    // console.log("FOOTER", FooterData);

    return (
        <>
            <UpWave background={background} />
            <footer className={` flex flex-col  justify-center pb-3  text-[#1F3934]`}
                style={{ backgroundColor: `${background}` }}
            >
                <div className="flex-col flex w-full xl:w-[85%] md:flex md:p-3 xl:justify-center items-center md:border-b-0.5 xl:h-[220px] md:border-gray-300 xl:mx-auto  ">
                    <div className=" p-1 " data-aos="fade-up">
                        <div >
                            <Link to="/" className="flex mx-auto md:flex  justify-center my-2 md:my-0 md:justify-start  scale-[70%] md:scale-[80%] lg:scale-[90%] xl:scale-[100%]">
                                <LOGOSVG />
                            </Link>
                            <div className="mt-6  font-body w-80  text-1  ">
                                {FooterData?.FooterDescriptionText}
                            </div>
                        </div>

                    </div>
                    <div className="mb-3 text-[14px] xl:text-[18px]  h-[20px] "><a onClick={(e) => LogGoogleAnalyticsEvent(e)} href="/privacypolicy" target="_blank"><div className=" hover:border-b-[2px] w-fit border-[#028577]  xl:hover:text-[18.1px] hover:text-[14.1px] ">Privacy Policy</div></a></div>

                </div>
                <div className="w-11/12  text-center mx-auto py-12 text-[12px] md:text-[14px] lg:text-[16px]  xl:text-[18px] mt-[20px]  h-[20px] border-t-[0.5px]  border-[#C8C8C8]">
                    {FooterData?.CopyrightVersionText}
                </div>
            </footer>
        </>
    );
}

export default LandingPageFooter;
